import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { Link } from 'gatsby'
import ToolCard from '../../components/ToolCard'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Ultimate guides for Podcasters" description="Free guides built by EchoWave for Podcasters" />

        <h1>Guides</h1>
        <p>
          List of our podcasting & promotion guides written by the EchoWave Team; Help us make thease better and
          feedback what you want to see more/less of. In these guides we focusing on improving your Podcasts though
          actionable strategy's and tips.
        </p>

        <p>
          You can follow us on Twitter <a href="https://www.twitter.com/echowaveio">@echowaveio</a> to keep updates when
          we post a new guide
        </p>

        <h2>Podcast Marketing</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ToolCard
            link="/how-to-post-audio-on-facebook/"
            src="/images/audio-convert.jpg"
            title="How To Post Audio On Facebook"
            description="Four Easy steps to share your audio on facebook!"
          />

          <ToolCard
            link="/podcast-to-video/"
            src="/images/podcast-coffee.jpg"
            title="Echowave Guide"
            description="Step by Step walk-though of converting Audio Files into sharable Podcast videos"
          />

          <ToolCard
            link="/podcast-teaser-trailer/"
            src="/images/podcast-trailer.jpg"
            title="Podcast Trailers"
            description="Every wondered why make a podcast trailer, here are a few key benefits"
          />

          <ToolCard
            link="/podcast-promotion/"
            src="/images/podcast-promotion.jpg"
            title="Podcast Promotion"
            description="Our full podcast promotion strategy guide."
          />

          <ToolCard
            link="/remote-podcast-recording/"
            src="/images/talking.jpg"
            title="Remote Podcast Recording"
            description="Missing Body Language while doing Remote Podcast."
          />
        </div>

        <h2>Podcast Guides</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ToolCard
            link="/how-to-start-a-podcast/"
            src="/images/how-to-start-a-podcast.jpg"
            title="How To Start a Podcast"
            description="Step by step podcast guide that will provide all the information you need to get going"
          />

          <ToolCard
            link="/podcast-questions-list/"
            src="/images/podcast-questions-list.jpg"
            title="Podcast Question List"
            description="List of interesting podcast questions to ask your guest"
          />

          <ToolCard
            link="/how-to-interview-for-a-podcast/"
            src="/images/podcast-interview.jpg"
            title="How To Interview for a Podcast"
            description="Get the best content from your guest interview"
          />

          <ToolCard
            link="/videocasts/"
            src="/images/videocast.jpg"
            title="Video Podcasts"
            description="In recent years, audio podcasts have taken the world by storm, but video podcasts are also growing in popularity."
          />

          <ToolCard
            link="/how-to-cite-a-podcast/"
            src="/images/tools/podcast-citation-tool.jpg"
            title="Podcast Citation"
            description="Cite podcasts as a source of authority in Harvard, MLA, APA & Chicago styles"
          />

          <ToolCard
            link="/how-do-podcasts-make-money/"
            src="/images/tools/how-do-podcasts-make-money.jpg"
            title="How do podcasts make money?"
            description="Top methods the best podcasts are using to make money, and tips you can use to maxamise your earnings."
          />
        </div>
      </Layout>
    )
  }
}

export default Page
